<template>
    <div>
        <div class="pb-6">
            <checkbox-input
                v-model="form.isRecurring"
                name="isRecurring"
                label="labels.is-recurring"
                @input="noDateAndRecurring"
            ></checkbox-input>
        </div>

        <text-input
            v-show="form.isRecurring"
            v-model="form.recurringWeeks"
            v-validate="'required|numeric|min:1'"
            type="number"
            name="recurringWeeks"
            label="labels.recurring-weeks"
            :suffix="$t('labels.week')"
            :error-messages="errors.collect('recurringWeeks')"
            :data-vv-as="$t('labels.recurring-weeks')"
            :disabled="!form.isRecurring"
        ></text-input>

        <v-radio-group
            v-show="form.isRecurring"
            v-model="day"
            mandatory
            :label="$t('labels.recurring-day')"
            :disabled="!form.isRecurring"
            class="mt-3"
        >
            <div class="d-flex flex-wrap day-radio-select">
                <v-radio
                    v-for="n in 7"
                    :key="n"
                    :label="$t(`days_of_week.${n - 1}`).slice(0, 2)"
                    :value="n % 7"
                    class="mr-3"
                ></v-radio>
            </div>
        </v-radio-group>

        <date-picker-input
            v-show="form.isRecurring"
            v-model="recurDate"
            label="labels.next_occurence"
            :min="minRecurDate"
            only-date
        ></date-picker-input>
    </div>
</template>

<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/const/DateTimeFormats'

export default {
    components: {
        TextInput,
        CheckboxInput,
        DatePickerInput,
    },
    mixins: [FormPartMixin],
    computed: {
        minRecurDate() {
            return this.$date().format('YYYY-MM-DD')
        },
        recurDate: {
            get() {
                return this.$date(this.form.cutoffTime || undefined).format(
                    DATE_FORMAT
                )
            },
            set(date) {
                this.form.cutoffTime = this.setDate(this.form.cutoffTime, date)
                this.form.deliveryTime = this.setDate(
                    this.form.deliveryTime,
                    date
                )
            },
        },
        day: {
            get() {
                return parseInt(
                    this.form.cutoffTime
                        ? this.$date(this.form.cutoffTime).format('d')
                        : this.$date().add(1, 'day').format('d')
                )
            },
            set(day) {
                const originalCutoffWeek = this.$date(
                    this.form.originalCutoffTime
                ).isoWeek()
                const cutoffDate = this.$date(this.form.cutoffTime || undefined)
                const prevDay = cutoffDate.day()

                let newCutoff = cutoffDate.day(day)
                let newDelivery = this.$date(
                    this.form.deliveryTime || undefined
                ).day(day)

                // fix sunday being first day of week...
                if (day === 0 && prevDay !== 0) {
                    newCutoff = newCutoff.add(1, 'w')
                    newDelivery = newDelivery.add(1, 'w')
                } else if (day !== 0 && prevDay === 0) {
                    newCutoff = newCutoff.subtract(1, 'w')
                    newDelivery = newDelivery.subtract(1, 'w')
                }

                const weekDiff = newCutoff.isoWeek() - originalCutoffWeek
                if (weekDiff !== 0) {
                    newCutoff = newCutoff.subtract(weekDiff, 'w')
                    newDelivery = newDelivery.subtract(weekDiff, 'w')
                }

                if (this.$date().isSameOrAfter(newCutoff, 'day')) {
                    newCutoff = newCutoff.add(1, 'w')
                }
                if (this.$date().isSameOrAfter(newDelivery, 'day')) {
                    newDelivery = newDelivery.add(1, 'w')
                }

                this.form.cutoffTime = newCutoff.format(DATE_TIME_FORMAT)
                this.form.deliveryTime = newDelivery.format(DATE_TIME_FORMAT)
            },
        },
    },
    methods: {
        setDate(dateTime, newDate) {
            const date = newDate || this.$date().format(DATE_FORMAT)
            const [year, month, day] = date.split('-')
            let merged = this.$date(dateTime)
            merged = merged.set('year', parseInt(year))
            merged = merged.set('month', parseInt(month) - 1)
            merged = merged.set('date', parseInt(day))
            return merged.format(DATE_TIME_FORMAT)
        },
        noDateAndRecurring() {
            if (
                !this.form.isRecurring ||
                (this.form.cutoffTime && this.form.deliveryTime)
            ) {
                return
            }

            let tomorrow = this.$date().add(1, 'day')

            this.form.cutoffTime = tomorrow.format(DATE_TIME_FORMAT)
            this.form.deliveryTime = tomorrow.format(DATE_TIME_FORMAT)
        },
    },
}
</script>

<style lang="scss">
.day-radio-select {
    .v-radio {
        position: relative;
    }

    .v-input--selection-controls__input {
        $s: 34px;
        width: $s;
        height: $s;
    }

    .v-input--selection-controls__ripple {
        $s: 40px;
        height: $s;
        width: $s;
        top: calc(50% - 26px);
        left: -10px;
        z-index: 1;
    }

    .v-input--radio-group--column & .v-radio:not(:last-child):not(:only-child) {
        margin-bottom: 0;
    }

    .v-input--selection-controls & .v-radio > .v-label {
        $s: 34px;
        display: block;
        position: absolute !important;
        width: $s;
        height: $s;
        line-height: $s;
        border-radius: 50%;
        top: 1px;
        left: 0px !important;
        border: 1px solid var(--v-g-26-base);
        text-align: center;
        font-weight: bold;
        background-color: var(--v-g-e8-base);
    }

    .v-input--selection-controls & .v-radio.v-item--active > .v-label {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
        border-color: var(--v-primary-base);
    }
}
</style>
