<template>
    <div>
        <v-card v-if="showWarning" outlined class="red">
            <v-card-text class="white--text">
                {{ warningMessage }}
            </v-card-text>
        </v-card>

        <base-form
            ref="form"
            v-model="form"
            :api="apiClientType.RESIDENTIAL_CANTEENS"
            :mapper="mapperType.RESIDENTIAL_CANTEENS_FORM"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :show-progress="showProgress"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template v-slot:col1>
                        <h3 class="headline pt-3 pb-6 black--text">
                            {{ $t('labels.canteen_order_settings_title') }}
                        </h3>

                        <text-input
                            v-model="form.name"
                            v-validate="'required|max:255|space'"
                            name="name"
                            label="labels.name"
                            :error-messages="errors.collect('name')"
                            :data-vv-as="$t('labels.name')"
                        ></text-input>

                        <div class="pb-4">
                            <entity-select-input
                                v-model="form.address"
                                v-validate="'required'"
                                label="residential_canteen.address"
                                name="address"
                                :entity="
                                    selectApiClientType.RESIDENTIAL_CANTEEN_ADDRESS
                                "
                                :data-vv-as="$t('residential_canteen.address')"
                                :error-messages="errors.collect('address')"
                            ></entity-select-input>
                        </div>

                        <dates-common-form-part
                            v-model="form"
                        ></dates-common-form-part>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.canteen_occurences_title') }}
                        </h3>

                        <recur-common-form-part
                            v-model="form"
                        ></recur-common-form-part>
                    </template>
                    <template v-slot:col2>
                        <canteen-restaurant-form-part
                            v-model="form"
                            type="ResidentialCanteenRestaurant"
                            :restaurant-entity="
                                selectApiClientType.RESIDENTIAL_CANTEEN_RESTAURANT
                            "
                            :restaurant-query-param="{
                                residential_canteen_address_id: getAddressIdFromIri(
                                    form.address
                                ),
                            }"
                        ></canteen-restaurant-form-part>

                        <text-input
                            v-model="form.pickupOffset"
                            v-validate="'required|numeric'"
                            type="number"
                            name="pickupOffset"
                            label="labels.default_pickup_offset"
                            :suffix="$t('labels.minutes')"
                            :error-messages="errors.collect('pickupOffset')"
                            :data-vv-as="$t('labels.default_pickup_offset')"
                        ></text-input>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.canteen_notifications_title') }}
                        </h3>

                        <div class="pb-6">
                            <checkbox-input
                                v-model="form.sendNotificationWhenOpen"
                                name="sendNotificationWhenOpen"
                                label="labels.send_notification_when_open"
                            ></checkbox-input>
                        </div>

                        <text-input
                            v-model="form.sendNotificationBeforeCutoff"
                            v-validate="'required|numeric'"
                            type="number"
                            name="sendNotificationBeforeCutoff"
                            label="labels.send_notification_before_cutoff"
                            :suffix="$t('labels.minutes')"
                            :error-messages="
                                errors.collect('sendNotificationBeforeCutoff')
                            "
                            :data-vv-as="
                                $t('labels.send_notification_before_cutoff')
                            "
                        ></text-input>
                        <div class="g-b2--text mt-n3">
                            {{ notificationTime || '-' }}
                        </div>
                    </template>
                </form-two-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import routeType from '@/router/routeType'
import FormBase from '@/components/mixins/FormBase'
import CanteenEditDisableMixin from '@/components/mixins/CanteenEditDisableMixin'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import DatesCommonFormPart from '@/components/pages/canteen/DatesCommonFormPart'
import CanteenRestaurantFormPart from '@/components/pages/canteen/CanteenRestaurantFormPart'
import RecurCommonFormPart from '@/components/pages/canteen/RecurCommonFormPart'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import IdFromIri from '@/services/IdFromIri'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        DatesCommonFormPart,
        CanteenRestaurantFormPart,
        RecurCommonFormPart,
        TextInput,
        CheckboxInput,
        EntitySelectInput,
    },
    mixins: [FormBase, CanteenEditDisableMixin],
    data() {
        return {
            form: {
                name: null,
                openFrom: 12,
                cutoffTime: undefined,
                deliveryTime: undefined,
                relatedRestaurants: [],
                address: null,
                pickupOffset: 15,
                isRecurring: false,
                recurringWeeks: 1,
                sendNotificationBeforeCutoff: 30,
                sendNotificationWhenOpen: false,
            },
            entity: 'label.residential_canteens',
            editRoute: routeType.RESIDENTIAL_CANTEENS_EDIT,
            listRoute: routeType.RESIDENTIAL_CANTEENS,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        title() {
            return this.entityId
                ? this.$t('residential_canteen.edit_page_title', {
                      entityName: `${this.form?.name}`,
                  })
                : this.$t('residential_canteen.create_page_title')
        },
        notificationTime() {
            return this.form.cutoffTime
                ? this.$date(this.form.cutoffTime)
                      .subtract(
                          this.form.sendNotificationBeforeCutoff,
                          'minutes'
                      )
                      .format('YYYY-MM-DD HH:mm')
                : ''
        },
    },
    methods: {
        getAddressIdFromIri(addressIri) {
            if (addressIri) return IdFromIri.getId(addressIri)
        },
        editRedirectParams: (data) => ({ entityId: data.id }),
    },
}
</script>
