<template>
    <v-btn color="success" small @click="addCanteenRestaurant">
        <v-icon left>add</v-icon> {{ $t('actions.add') }}
    </v-btn>
</template>

<script>
export default {
    props: {
        basePickupOffset: {
            type: Number,
            default: 45,
        },
        type: {
            type: String,
            required: true,
        },
    },

    computed: {
        canteenRestaurantModel() {
            return {
                '@type': this.type,
                pickupOffset: this.basePickupOffset,
                restaurant: null,
            }
        },
    },

    methods: {
        addCanteenRestaurant() {
            const id = new Date().getTime()
            const model = { ...this.canteenRestaurantModel, '@id': id }
            this.$emit('added', model)
        },
    },
}
</script>
