import TimerCallbackMixin from '@/components/mixins/TimerCallbackMixin'

export default {
    mixins: [TimerCallbackMixin],
    data() {
        return {
            beforeOpenTime: true,
        }
    },
    computed: {
        openTime() {
            return this.form.cutoffTime
                ? this.$date(this.form.cutoffTime)
                      .subtract(this.form.openFrom, 'hour')
                      .format('YYYY-MM-DD HH:mm')
                : ''
        },
    },
    methods: {
        onTimerUpdate() {
            this.beforeOpenTime = this.$date().isSameOrBefore(
                this.openTime,
                'minute'
            )
        },
    },
}
