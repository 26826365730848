<template>
    <div>
        <date-picker-input
            v-show="!form.isRecurring"
            v-model="form.cutoffTime"
            v-validate="'required|dateafter'"
            label="labels.cc_cutoff.date"
            name="cutoffTime"
            :error-messages="errors.collect('cutoffTime')"
            :data-vv-as="$t('labels.cc_cutoff.date')"
            :min="minDate"
        ></date-picker-input>
        <date-picker-input
            v-show="form.isRecurring"
            v-model="cutoffTimeTime"
            label="labels.cc_cutoff.date"
            name="cutoffTimeTime"
            :error-messages="errors.collect('cutoffTime')"
            :data-vv-as="$t('labels.cc_cutoff.date')"
            only-time
        ></date-picker-input>

        <div class="d-flex">
            <date-picker-input
                v-show="!form.isRecurring"
                v-model="form.deliveryTime"
                v-validate="'required'"
                label="labels.cc_delivery_at.date"
                name="deliveryTime"
                class="w-100"
                :error-messages="errors.collect('deliveryTime')"
                :data-vv-as="$t('labels.cc_delivery_at.date')"
                :min="minDelivery"
            ></date-picker-input>
            <date-picker-input
                v-show="form.isRecurring"
                v-model="deliveryTimeTime"
                label="labels.cc_delivery_at.date"
                name="deliveryTimeTime"
                class="w-100"
                :error-messages="errors.collect('deliveryTime')"
                :data-vv-as="$t('labels.cc_delivery_at.date')"
                only-time
            ></date-picker-input>
            <!-- text field - for design purposes -->
            <v-text-field
                :value="`${$options.filters.toDate(
                    form.deliveryTime,
                    'HH:mm',
                    30
                )}`"
                prepend-inner-icon="remove"
                class="pl-2"
                readonly
            ></v-text-field>
        </div>

        <text-input
            v-model="form.openFrom"
            v-validate="'required|numeric'"
            type="number"
            name="openFrom"
            label="labels.open-from"
            :suffix="$t('labels.hours')"
            :error-messages="errors.collect('openFrom')"
            :data-vv-as="$t('labels.open-from')"
            :disabled="!beforeOpenTime"
        ></text-input>
        <div class="g-b2--text mt-n3">
            {{ openTime || '-' }}
        </div>
    </div>
</template>

<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import CanteenBeforeOpenTimeMixin from '@/components/mixins/CanteenBeforeOpenTimeMixin'
import TextInput from '@/components/form/Inputs/TextInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import { TIME_FORMAT, DATE_TIME_FORMAT } from '@/const/DateTimeFormats'

export default {
    components: {
        TextInput,
        DatePickerInput,
    },
    mixins: [FormPartMixin, CanteenBeforeOpenTimeMixin],
    computed: {
        minDate() {
            return this.$date().format('YYYY-MM-DD')
        },
        minDelivery() {
            return this.form.cutoffTime || this.minDate
        },
        cutoffTimeTime: {
            get() {
                return this.$date(this.form.cutoffTime || undefined).format(
                    TIME_FORMAT
                )
            },
            set(newTime) {
                this.form.cutoffTime = this.setTime(
                    this.form.cutoffTime,
                    newTime
                )
            },
        },
        deliveryTimeTime: {
            get() {
                return this.$date(this.form.deliveryTime || undefined).format(
                    TIME_FORMAT
                )
            },
            set(newTime) {
                this.form.deliveryTime = this.setTime(
                    this.form.deliveryTime,
                    newTime
                )
            },
        },
    },
    methods: {
        setTime(dateTime, newTime) {
            const [hour, minute] = newTime.split(':')
            let merged = this.$date(dateTime)
            merged = merged.set('hour', hour)
            merged = merged.set('minute', minute)
            return merged.format(DATE_TIME_FORMAT)
        },
    },
}
</script>
