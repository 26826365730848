<template>
    <div>
        <form-panel>
            <form-two-col-row>
                <template slot="col1">
                    <text-input
                        v-model="form.name"
                        v-validate="'required|max:255|space'"
                        name="name"
                        label="labels.name"
                        :error-messages="errors.collect('name')"
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                </template>
                <template v-if="!hideInvoiceName" slot="col2">
                    <text-input
                        v-model="form.invoiceName"
                        v-validate="'max:255|space'"
                        name="invoiceName"
                        label="labels.invoice_name"
                        :error-messages="errors.collect('invoiceName')"
                        :data-vv-as="$t('labels.invoice_name')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template slot="col1">
                    <text-input
                        v-model="form.phone"
                        v-validate="'required|phonenumber|max:255'"
                        name="phone"
                        label="labels.phone"
                        :error-messages="errors.collect('phone')"
                        :data-vv-as="$t('labels.phone')"
                        @blur="form.phone = formatPhoneNumber(form.phone)"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel>
            <form-two-col-row>
                <template slot="col1">
                    <address-autocomplete-input
                        v-model="form.fullAddress"
                        v-validate="'required|max:255'"
                        label="labels.address"
                        name="fullAddress"
                        :error-messages="errors.collect('fullAddress')"
                        :data-vv-as="$t('labels.address')"
                        :longitude.sync="form.longitude"
                        :latitude.sync="form.latitude"
                        :house-number.sync="form.houseNumber"
                        :street.sync="form.street"
                        :town.sync="form.town"
                        :zip-code.sync="form.zipCode"
                        :sublocality.sync="form.sublocality"
                        :administrative-area-level1.sync="
                            form.administrativeAreaLevel1
                        "
                        :administrative-area-level2.sync="
                            form.administrativeAreaLevel2
                        "
                        :administrative-area-level3.sync="
                            form.administrativeAreaLevel3
                        "
                        :administrative-area-level4.sync="
                            form.administrativeAreaLevel4
                        "
                        :country.sync="form.country"
                        :country-code.sync="form.countryCode"
                        :place-id.sync="form.placeId"
                    ></address-autocomplete-input>

                    <div class="d-none d-lg-block mt-8">
                        <text-input
                            v-model="form.subAddress"
                            v-validate="'max:255|space'"
                            name="subAddress"
                            label="labels.address_details"
                            :error-messages="errors.collect('subAddress')"
                            :data-vv-as="$t('labels.address_details')"
                        ></text-input>
                        <text-input
                            v-model="form.comment"
                            v-validate="'max:255|space'"
                            name="comment"
                            label="labels.comment"
                            :error-messages="errors.collect('comment')"
                            :data-vv-as="$t('labels.comment')"
                        ></text-input>
                    </div>
                </template>
                <template slot="col2">
                    <address-map-input
                        v-model="form.fullAddress"
                        style="height: 300px"
                        :longitude.sync="form.longitude"
                        :latitude.sync="form.latitude"
                        :house-number.sync="form.houseNumber"
                        :street.sync="form.street"
                        :town.sync="form.town"
                        :zip-code.sync="form.zipCode"
                        :sublocality.sync="form.sublocality"
                        :administrative-area-level1.sync="
                            form.administrativeAreaLevel1
                        "
                        :administrative-area-level2.sync="
                            form.administrativeAreaLevel2
                        "
                        :administrative-area-level3.sync="
                            form.administrativeAreaLevel3
                        "
                        :administrative-area-level4.sync="
                            form.administrativeAreaLevel4
                        "
                        :country.sync="form.country"
                        :country-code.sync="form.countryCode"
                        :place-id.sync="form.placeId"
                    ></address-map-input>
                </template>
            </form-two-col-row>
            <form-one-col-row class="d-block d-lg-none mt-8">
                <text-input
                    v-model="form.subAddress"
                    v-validate="'max:255|space'"
                    name="subAddress"
                    label="labels.address_details"
                    :error-messages="errors.collect('subAddress')"
                    :data-vv-as="$t('labels.address_details')"
                ></text-input>
                <text-input
                    v-model="form.comment"
                    v-validate="'max:255|space'"
                    name="comment"
                    label="labels.comment"
                    :error-messages="errors.collect('comment')"
                    :data-vv-as="$t('labels.comment')"
                ></text-input>
            </form-one-col-row>
            <div v-if="innerForm" class="d-flex justify-end px-4 pt-4 mt-8">
                <div>
                    <v-btn
                        v-if="form.id"
                        class="mr-4"
                        color="warning"
                        @click="deleteAddress"
                    >
                        {{ $t('actions.delete') }}
                    </v-btn>
                    <v-btn
                        v-else
                        variant="secondary"
                        class="mr-3"
                        @click="cancel"
                    >
                        {{ $t('actions.cancel') }}
                    </v-btn>
                    <v-btn
                        color="success"
                        :disabled="!form.name || !form.fullAddress"
                        @click="save"
                    >
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </div>
        </form-panel>
    </div>
</template>

<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import MapperCollection from '@/services/mapper/MapperCollection'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        AddressMapInput,
        AddressAutocompleteInput,
    },
    mixins: [FormPartMixin, PhoneNumberMixin],

    props: {
        innerForm: Boolean,
        hideInvoiceName: Boolean,
        user: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            api: RestApiType.USER_ADDRESSES,
            mapper: MapperType.USER_ADDRESSES_FORM,
        }
    },

    watch: {
        user: {
            handler() {
                if (this.user && this.user.id) {
                    this.$set(this.form, 'phone', this.user.phone)
                    this.$set(this.form, 'userProfile', this.user['@id'])
                }
            },
            immediate: true,
        },
        $route() {
            if (this.innerForm) {
                this.$validator.reset()
                this.$set(this, 'form', this.value)
            }
        },
    },

    methods: {
        save() {
            if (!this.form.name || !this.form.fullAddress) return

            if (this.form.id) {
                RestApiCollection.get(this.api)
                    .update(this.form, this.form.id)
                    .then(() => {
                        this.$emit('updateList')
                    })
            } else {
                this.form.userProfile = this.user['@id']

                const entity = MapperCollection.get(this.mapper).toEntity(
                    this.form
                )

                RestApiCollection.get(this.api)
                    .create(entity)
                    .then(() => {
                        this.$emit('updateList')
                    })
            }
        },
        deleteAddress() {
            RestApiCollection.get(this.api)
                .delete(this.form.id)
                .then(() => {
                    this.$emit('updateListAfterDelete')
                    this.cancel()
                })
        },
        cancel() {
            this.form = {
                phone: this.user.phone,
                userProfile: this.user['@id'],
            }
            this.$emit('cancel')
        },
    },
}
</script>
