<template>
    <div>
        <div class="py-3 canteen-restaurant-headline">
            <v-input
                v-model="form.relatedRestaurants"
                v-validate="'required'"
                hide-details="auto"
                name="relatedRestaurants"
                :data-vv-as="$t('labels.restaurants')"
                :error-messages="errors.collect('relatedRestaurants')"
            >
                <h3 class="headline">
                    {{ $t('labels.restaurants') }}
                </h3>
            </v-input>
        </div>

        <v-row
            v-for="(relation, index) in form.relatedRestaurants"
            :key="relation['@id']"
            class="pt-2 mb-n4"
        >
            <v-col cols="7" class="pb-0 pt-2">
                <entity-select-input
                    v-model="relation.restaurant"
                    v-validate="'required'"
                    :name="`relationRestaurant[${relation['@id']}]`"
                    label="labels.restaurant"
                    :data-vv-as="$t('labels.restaurant')"
                    :entity="restaurantEntity"
                    :query-params="restaurantQueryParam"
                    :items-filter="canteenRestaurantsFilter(relation)"
                    :needs-update="relationSelectUpdate"
                    :error-messages="
                        errors.collect(`relationRestaurant[${relation['@id']}]`)
                    "
                    :disabled="!isRelationRemovable(relation)"
                    @input="relationSelectUpdate = !relationSelectUpdate"
                ></entity-select-input>
            </v-col>
            <v-col cols="3" class="py-0">
                <text-input
                    v-model="relation.pickupOffset"
                    v-validate="'required|numeric'"
                    type="number"
                    :name="`pickupOffset[${relation['@id']}]`"
                    label="labels.pickup-offset"
                    :suffix="$t('labels.minutes').substring(0, 1)"
                    :error-messages="
                        errors.collect(`pickupOffset[${relation['@id']}]`)
                    "
                    :data-vv-as="$t('labels.pickup-offset')"
                ></text-input>
                <div class="g-b2--text mt-n3">
                    {{ pickupDates[index] || '-' }}
                </div>
            </v-col>
            <v-col cols="2" class="pb-0 pt-3">
                <v-btn
                    v-if="isRelationRemovable(relation)"
                    icon
                    color="error"
                    @click="removeRestaurantRelation(index)"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <div class="d-flex px-3 py-5 justify-end">
            <add-canteen-restaurant
                :type="type"
                :base-pickup-offset="form.pickupOffset"
                @added="addCanteenRestaurant"
            ></add-canteen-restaurant>
        </div>
    </div>
</template>

<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import CanteenBeforeOpenTimeMixin from '@/components/mixins/CanteenBeforeOpenTimeMixin'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import TextInput from '@/components/form/Inputs/TextInput'
import AddCanteenRestaurant from '@/components/pages/canteen/AddCanteenRestaurant'

export default {
    components: {
        EntitySelectInput,
        TextInput,
        AddCanteenRestaurant,
    },
    mixins: [FormPartMixin, CanteenBeforeOpenTimeMixin],
    props: {
        type: {
            type: String,
            required: true,
        },
        restaurantEntity: {
            type: String,
            required: true,
        },
        restaurantQueryParam: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            relationSelectUpdate: false,
        }
    },
    computed: {
        pickupDates() {
            return this.form.relatedRestaurants.map((el) =>
                this.form.deliveryTime
                    ? this.$date(this.form.deliveryTime)
                          .subtract(el.pickupOffset, 'minutes')
                          .format('MM-DD HH:mm')
                    : ''
            )
        },
    },
    methods: {
        isRelationRemovable(relation) {
            return typeof relation['@id'] === 'number' || this.beforeOpenTime
        },
        removeRestaurantRelation(index) {
            this.form.relatedRestaurants.splice(index, 1)
            this.relationSelectUpdate = !this.relationSelectUpdate
        },
        addCanteenRestaurant(val) {
            if (
                this.form.relatedRestaurants &&
                Array.isArray(this.form.relatedRestaurants)
            ) {
                this.$set(this.form, 'relatedRestaurants', [
                    ...this.form.relatedRestaurants,
                    val,
                ])
            } else {
                this.$set(this.form, 'relatedRestaurants', [val])
            }
        },
        canteenRestaurantsFilter(relation) {
            return (values) =>
                values.filter(
                    (value) =>
                        value.key === relation.restaurant ||
                        !this.form.relatedRestaurants ||
                        this.form.relatedRestaurants.length === 0 ||
                        !this.form.relatedRestaurants
                            .map((rel) => rel.restaurant)
                            .includes(value.key)
                )
        },
    },
}
</script>

<style>
.canteen-restaurant-headline .v-input__slot {
    margin-bottom: 0;
}
</style>
